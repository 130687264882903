<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
			:slot_table_list="['operation','status','city']" :submit_preprocessing="submit_preprocessing" ref="list"
			:rowKey="'id'">
			<template slot="left_btn">
				<div class="left_btn_box">
					<div class="recharge_all">
						<p>打卡数量<span class="all_money">{{all_data.total}}</span></p>
						<p>异常数量<span class="all_money">{{all_data.unusual}}</span></p>
					</div>
				</div>
			</template>
			<template slot="right_btn">
				<a :href="'/manage/crm.hotel/exportClockIn' | export_form"  target="_blank">
					<a-button  class="a_btn">导出</a-button>
				</a>
			</template>
			<template slot="operation" slot-scope="data">
				<span>
					<a @click="see(data.record)">查看</a>
				</span>
			</template>
		</TableList>

	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import SelectUser from "@/components/SelectUser";

	import {
		clockList,
		clockCensus
	} from "@/api/evection";
	import {
		getUserList
	} from "@/api/personnel";

	const columns = [{
			title: "员工姓名",
			dataIndex: "username"
		},
		{
			title: "部门",
			dataIndex: "department"
		},
		{
			title: "酒店地址",
			dataIndex: "hotel_address"
		},
		{
			title: "入住打卡地点",
			dataIndex: "live_address",

		},
		{
			title: "距离差",
			dataIndex: "live_distance",
		},

		{
			title: "离店打卡地点",
			dataIndex: "left_address",
		},
		{
			title: "距离差",
			dataIndex: "left_distance",

		},
		{
			title: "入住时间",
			dataIndex: "live_time",
		},
		{
			title: "操作",
			dataIndex: "operation",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];



	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
			SelectUser
		},
		data() {
			return {
				config: this.$config,
				get_table_list: clockList,
				submit_preprocessing: {
					array_to_string: ['department_id', 'group_id']
				},
				columns,
				selectedRowKeys: [],
				loading: false,
				visible: false,
				form_data_seo: {
					list: [

						{
							type: "text",
							name: "username",
							title: "员工姓名",
							placeholder: "请输入员工姓名",
							options: {}
						},
						{
							type: "tree-select",
							name: "department_id",
							title: "部门",
							options: {},
							treeData: [],
							multiple: true
						},
						{
							type: "text",
							name: "address",
							title: "酒店地址",
							placeholder: "酒店地址",
							options: {}
						},
						{
							type: 'select',
							title: '距离差',
							name: 'distance',
							options: {},
							list: [{
									key: '500',
									value: "小于500米"
								},
								{
									key: '1000',
									value: "小于1000米"
								},
								{
									key: '2000',
									value: "小于2000米"
								},
								{
									key: '2001',
									value: "大于2000米"
								},
							]
						},
						{
							type: "range_date",
							name: "range_date",
							title: "入住时间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},

					],
					...this.$config.form_data_seo
				},
				all_data: {}
			};
		},


		async created() {
			this.get_info()
			Promise.all([this.$method.get_department()]).then(
				res => {
					this.form_data_seo.list.forEach(item => {
						if (item.name == "department_id") {
							item.treeData = res[0];
						}
						
					});
				});
			
		},
		methods: {
			see(key) {
				this.$router.push("/evection/hotel_clock_details?id=" + key.id);
			},
			get_info() {
				clockCensus().then(res => {
					this.all_data = res.data.data
				})
			},

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.left_btn_box {
		flex: inherit;

		p {
			margin: 5px 0px;
		}
	}

	.edit_pop {
		.ant-col-19 {
			width: 77%;
		}

		.ant-form-item-label {
			width: 22%;
		}
	}

	.red_color {
		color: red;
	}

	.green_color {
		color: #00CC10;
	}

	.recharge_all {
		font-size: 16px;
		display: flex;
		color: #333;

		.all_money {
			font-size: 20px;
			color: #FF0000;
			margin-left: 10px;
			margin-right: 5px;
			display: inline-block;
		}

		p:nth-child(2),p:nth-child(3) {
			margin-left: 40px;
		}
	}
</style>
